import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AtomsModule } from '@shared/components/atoms/atoms.module';
import { AddCourseComponent } from '@shared/components/molecules/add-course/add-course.component';
import { ChangeQualificationComponent } from '@shared/components/molecules/change-qualification/change-qualification.component';
import { CopyComponent } from '@shared/components/molecules/copy/copy.component';
import { CourseMinimalViewComponent } from '@shared/components/molecules/course-minimal-view/course-minimal-view.component';
import { MultiOptionsSelectorComponent } from '@shared/components/molecules/multi-options-selector/multi-options-selector.component';
import { ShowHideComponent } from '@shared/components/molecules/show-hide/show-hide.component';

import { ActionMenuComponent } from './action-menu/action-menu.component';
import { AddressPredictionComponent } from './address-prediction/address-prediction.component';
import { AddressSelectorComponent } from './address-selector/address-selector.component';
import { ApplicationHintsComponent } from './application-hints/application-hints.component';
import { ApplicationTileComponent } from './application-tile/application-tile.component';
import { ApplicationYearListItemComponent } from './application-year-list-item/application-year-list-item.component';
import { AssociatedPersonComponent } from './associated-person/associated-person.component';
import { CheckboxInputGroupComponent } from './checkbox-input-group/checkbox-input-group.component';
import { CoeDiffComponent } from './coe-diff/coe-diff.component';
import { ContentSearchBarComponent } from './content-search-bar/content-search-bar.component';
import { CourseCardComponent } from './course-card/course-card.component';
import { CoursePlannerTileComponent } from './course-planner-tile/course-planner-tile.component';
import { CourseSearchComponent } from './course-search/course-search.component';
import { CourseSearchTableViewComponent } from './course-search-table-view/course-search-table-view.component';
import { CourseSummaryCardComponent } from './course-summary-card/course-summary-card.component';
import { CourseViewComponent } from './course-view/course-view.component';
import { DateTimeSelectorComponent } from './date-time-selector/date-time-selector.component';
import { EthnicityIwiSelectorsComponent } from './ethnicity-iwi-selectors/ethnicity-iwi-selectors.component';
import { FileListComponent } from './file-list/file-list.component';
import { FileListContainerComponent } from './file-list-container/file-list-container.component';
import { FitnessToTeachComponent } from './fitness-to-teach/fitness-to-teach.component';
import { FlashMessageComponent } from './flash-message/flash-message.component';
import { FooterComponent } from './footer/footer.component';
import { FooterMinimalComponent } from './footer-minimal/footer-minimal.component';
import { FullNameComponent } from './full-name/full-name.component';
import { GenderSelectorComponent } from './gender-selector/gender-selector.component';
import { HeaderNavDropdownComponent } from './header-nav-dropdown/header-nav-dropdown.component';
import { PersonalPronounsSelectorComponent } from './personal-pronouns-selector/personal-pronouns-selector.component';
import { PersonalStatementComponent } from './personal-statement/personal-statement.component';
import { PhoneSelectorComponent } from './phone-selector/phone-selector.component';
import { ProcessHelpModalButtonComponent } from './process-help-modal-button/process-help-modal-button.component';
import { ProcessInfoPanelComponent } from './process-info-panel/process-info-panel.component';
import { QualificationSelectorComponent } from './qualification-selector/qualification-selector.component';
import { RadioInputGroupComponent } from './radio-input-group/radio-input-group.component';
import { ReferenceDataMultiSelectorComponent } from './reference-data-multi-selector/reference-data-multi-selector.component';
import { ReferenceDataSelectorComponent } from './reference-data-selector/reference-data-selector.component';
import { ResponsiveGridComponent } from './responsive-grid/responsive-grid.component';
import { SocialLoginComponent } from './social-login/social-login.component';
import { StaticLinkDashboardTileComponent } from './static-link-dashboard-tile/static-link-dashboard-tile.component';
import { StudentLoanComponent } from './student-loan/student-loan.component';
import { StudyPlanSummaryBarComponent } from './study-plan-summary-bar/study-plan-summary-bar.component';
import { TaskComponent } from './task/task.component';
import { TaskHintComponent } from './task-hint/task-hint.component';
import { TaskHintsComponent } from './task-hints/task-hints.component';
import { YesNoComponent, YesNoOtherOptionComponent, YesNoContentComponent } from './yes-no/yes-no.component';

const molecules = [
  ActionMenuComponent,
  AddCourseComponent,
  AddressPredictionComponent,
  AddressSelectorComponent,
  ApplicationHintsComponent,
  ApplicationTileComponent,
  ApplicationYearListItemComponent,
  AssociatedPersonComponent,
  ChangeQualificationComponent,
  CheckboxInputGroupComponent,
  CoeDiffComponent,
  ContentSearchBarComponent,
  CopyComponent,
  CourseCardComponent,
  CourseMinimalViewComponent,
  CoursePlannerTileComponent,
  CourseSearchComponent,
  CourseSearchTableViewComponent,
  CourseSummaryCardComponent,
  CourseViewComponent,
  DateTimeSelectorComponent,
  EthnicityIwiSelectorsComponent,
  FileListComponent,
  FileListContainerComponent,
  FitnessToTeachComponent,
  FlashMessageComponent,
  FooterComponent,
  FooterMinimalComponent,
  FullNameComponent,
  GenderSelectorComponent,
  MultiOptionsSelectorComponent,
  HeaderNavDropdownComponent,
  PersonalPronounsSelectorComponent,
  PersonalStatementComponent,
  PhoneSelectorComponent,
  ProcessHelpModalButtonComponent,
  ProcessInfoPanelComponent,
  QualificationSelectorComponent,
  RadioInputGroupComponent,
  ReferenceDataMultiSelectorComponent,
  ReferenceDataSelectorComponent,
  ResponsiveGridComponent,
  ShowHideComponent,
  SocialLoginComponent,
  StaticLinkDashboardTileComponent,
  StudentLoanComponent,
  StudyPlanSummaryBarComponent,
  TaskComponent,
  TaskHintComponent,
  TaskHintsComponent,
  YesNoComponent,
  YesNoContentComponent,
  YesNoOtherOptionComponent,
];

@NgModule({
  declarations: molecules,
  imports: [
    AtomsModule,
    CommonModule,
    NgMultiSelectDropDownModule,
    NgSelectModule,
    NgxDatatableModule,
    ReactiveFormsModule,
    RouterModule,
  ],
  exports: molecules,
})
export class MoleculesModule {}
